.mainDiv {

}

.middle {
    /*background-color: #eeebe6;*/
    background-color: white;
    box-shadow: 0px 0px 10px black;
    /*z-index: -1;*/
    padding-top: 60px;
    /*min-height: 100%;*/
    max-width: 100%;
    width: 100%;
    overflow-x:hidden;
    overflow-y:scroll;
}

.coreLayoutDiv {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    justify-content: center;
    min-height: 50px;
    flex-grow: 1;
    background-color: #E47F44;
}
