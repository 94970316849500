html {
    /*box-sizing: border-box;*/
}

html, body {
    overflow: auto;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    font-family: "Open Sans", "Book Antiqua", Palatino, serif;
    color: #707070;


}

html {
    /*background: url('/bk2.jpg') no-repeat center center fixed;*/
    /*background-color: rgb(185, 84, 20); !* For browsers that do not support gradients *!*/
    /*background-image: linear-gradient(to bottom right, rgb(212, 170, 140), rgb(185, 84, 20));*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

a {
    color : #E47F44;
    text-decoration: none !important;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

/* [type=button]{
  -webkit-appearance: none !important;
} */

.Menulinks {
    color: #C73515;
    padding-right: 10px;
    /*font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;*/
    font-family: "Open Sans", "Book Antiqua", Palatino, serif;
}


h1 h2 h3 h4 h5 h6 {
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}
