.navbarDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbarMiddle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navbarMiddleDesktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 200px);
}

.navbarLogoDiv {
    display: flex;
    justify-content: flex-start;
}

.navbarLinksDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
