.topImage {
    overflow: hidden;
    width: 100%;
    height: 150px;
    position: absolute;
    top: -5px;


    background-image: url(/pos5.jpg);
    background-repeat:no-repeat;
    background-size:cover;

    background-position: center;

    z-index: -1;

}

.topImage img {
    position: absolute;
    top : -120px;
    width: 100%;
}

.topImage h2 {
    color: white;
    position: absolute;
    letter-spacing: 5px;
    top: 50%;
    left: 50%;
    width: 90%;
    text-align:center;
    transform: translate(-50%, -50%);
}
