.icons {
    font-size: 32px;
}
.icons a {
    padding: 10px;
}

.ourmessagesArea {
    padding: 15px;
}

.contactMap {
    width: 100%;
    height: 400px;
    margin: 10px;
    border: none;
}


.topContactDiv {
    display: flex;
    flex-direction:column;
    justify-content: center;

    align-items: center;

    width: 100%;
}


.topContactDesktop {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 200px);
}

.topContactPhone {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 20px);
}
