.usTopDiv {
    display: flex;
    flex-direction:column;
    justify-content: center;

    align-items: center;

    width: 100%;
}

.usDivDesktop {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 200px);
}

.usDivPhone {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 20px);
}

h2 {
    text-align: center;
}

.usmain {
    padding: 10px;
}
