.homediv {
    position: fixed;
    background: url("/pos5.jpg") no-repeat center center fixed;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.homediv2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.homediv h2 {
    position: relative;
    font-family: 'Raleway', sans-serif;
    font-size: 24px!important;
}

.textDiv {
    color: white;
    display: flex;
    margin: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);

}

.textDiv h2 {
    margin: 0;
}
