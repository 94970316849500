
.CustomButton {
    background-color: #E47F44;
    color: white;
    border: 0;
    margin: 5px;
    border-radius: 0;
    height: 34px;
    font-family: "Open Sans", "Book Antiqua", Palatino, serif;
    font-weight: bold;
    min-width: 120px;
}
