.smallObituaryPreview {
    background-color: white;
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 280px;
    border-bottom: 1px solid darkgrey;
}

.smallObituaryPreview:hover {
    background-color: lightgrey;
}

.smallObituaryPreviewDescription {
    display: flex;
    flex-direction: column;
}

.smallObituaryPreview * {
    margin: 5px;

}
