.container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-family: 'Raleway', sans-serif;
    color: #4a4a4a;
}

.createCondolences {
    width: 100%;
}


.condolences {
    width: 100%;
}

.createButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.condolence {
    padding: 10px;
    border-radius: 3px;
    background-color: #d3d0cb;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-family: 'Raleway', sans-serif;
    color: #4a4a4a;
    margin: 10px;
}

.condolenceAuthor {
    margin-bottom: 0;
}

.condolenceMessage {
    margin-top: 0;
    margin-bottom: 5px;
}

.condolenceMail {
    align-self:flex-end;
    margin-bottom: 0px;
}
