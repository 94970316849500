.cinput {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cinput span {
    font-size: 13px;
    margin-bottom: 5px;
    color: #707070;
}

.cinput input {
    padding: 5px;
    height: 34px;
    /*border-radius: 5px;*/
    border: 1px solid #A5A5A5;
}

.cinput textarea{
    border: 1px solid #d3d0cb;
}


.MuiInputBase-input {
    padding: 5px;
    height: 34px;
    /*border-radius: 5px;*/
    border: 1px solid #A5A5A5;
}
